@font-face {
  font-family: Ilisarniq;
  src: url("../../fonts/Ilisarniq/Ilisarniq-Black.otf") format("opentype");
}
@font-face {
  font-family: Avenir;
  src: url("../../fonts/avenir/AvenirLTStd-Book.otf") format("opentype");
}

main {
  @apply bg-main-dark;
  @apply text-main-light;
}

@layer base {
  body {
    @apply font-main;
    @apply text-base;
    word-break: break-word;
    @apply bg-main-dark;
  }

  h1 {
    @apply font-headline;
    @apply uppercase;
    @apply text-3xl #{!important};
    @apply bg-white;
    @apply text-main-dark;
    @apply inline;
    line-height: 1.4 !important;
  }
  h2 {
    @apply font-headline;
    @apply uppercase;
    @apply text-2xl #{!important};
    @apply bg-white;
    @apply text-main-dark;
    @apply inline;
    line-height: 1.4 !important;
  }
  h3 {
    @apply font-headline;
    @apply uppercase;
    @apply text-xl #{!important};
    @apply bg-white;
    @apply text-main-dark;
    @apply inline;
    line-height: 1.4 !important;
  }
  h4 {
    @apply font-headline;
    @apply text-base;
    @apply uppercase;
    //@apply mb-10;
    line-height: 1.4 !important;
  }

  @screen lg {
    body {
      word-break: normal;
    }

    h1 {
      @apply text-5xl #{!important};
      line-height: 1.4 !important;
    }
    h2 {
      @apply text-4xl #{!important};
      line-height: 1.4 !important;
    }
    h3 {
      @apply text-2xl #{!important};
      line-height: 1.4 !important;
    }
    h4 {
      @apply text-xl;
      line-height: 1.4 !important;
    }

  }
}

.hyphens {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
