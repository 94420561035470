@tailwind base;
@tailwind components;
@tailwind utilities;

@import "base/typegrafie";


.react-multi-carousel {
  position: initial;
}
.react-multi-carousel .react-multi-carousel-track  {
  display: flex !important;
}

.react-multi-carousel .slick-slide {
  height: inherit !important;
}
.react-multi-carousel .slick-slide > * {
  height: 100% !important;
}
.react-multi-carousel .react-multi-carousel-list {
  overflow: visible !important;
}

.button{
  @apply bg-highlight text-main-dark inline-block text-center text-lg font-headline py-2 px-4;
}
.button-inline{
  @apply text-white text-center text-lg font-headline py-2 px-4;
}

.iframe_container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.iframe_container .responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
