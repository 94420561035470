.because_we {
  @apply -mx-6
}
.because_we .slick-slide {
  height: inherit !important;
  @apply border-[.1px] border-white mx-6 rounded-lg overflow-hidden
}


.hero_inner:before {
  content: '';
  width: 100%;
  height: 70vh;
  display: block;
  position: absolute;
  top: 20%;
  left: 0;
  background: linear-gradient(180deg, #1b1b1e 0%, rgba(10, 10, 10, 0.73) 80.2%, rgba(10, 10, 10, 0) 100%);
  z-index: 0;
  pointer-events: none;
}
